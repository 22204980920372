// src/components/Dashboard.js

// src/components/Dashboard.js
import React, { useContext } from 'react';
import { Grid, Box } from '@mui/material';
import { GoogleSheetContext } from '../GoogleSheetProvider';
import DashboardItem from './DashboardItem';

const Dashboard = () => {
  const data = useContext(GoogleSheetContext);

  if (!data) return <div>Loading...</div>;

  // Assurer la correspondance des clés
  const groupedData = {
    Temperature: data.map(row => ({ time: row.Time, value: row['Tempurature (°C)'] })),
    TensionTotal: data.map(row => ({ time: row.Time, value: row.V1 })),
    TensionBatterie: data.map(row => ({ time: row.Time, value: row.V2 })),
    IntensiteA: data.map(row => ({ time: row.Time, value: row.A1 })),
    // Ajouter d'autres types de données si nécessaire
  };

  return (
    <Box textAlign="center">
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <DashboardItem title="Temperature" data={groupedData.Temperature} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardItem title="TensionTotal" data={groupedData.TensionTotal} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardItem title="Tension Batterie" data={groupedData.TensionBatterie} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardItem title="Intensite A" data={groupedData.IntensiteA} />
        </Grid>
        {/* Ajouter d'autres DashboardItem ici */}
      </Grid>
    </Box>
  );
};

export default Dashboard;
