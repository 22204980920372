// src/components/DashboardItem.js
import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ChartModal from './ChartModal';

const iconMap = {
  Temperature: <ThermostatIcon />,
  TensionTotal: <FlashOnIcon />,
  'Tension Batterie': <BatteryChargingFullIcon />,
  'Intensite A': <ShowChartIcon />,
  // Ajouter d'autres icônes ici
};

const DashboardItem = ({ title, data }) => {
  const [showGraph, setShowGraph] = useState(false);

  const handleClick = () => {
    setShowGraph(!showGraph);
  };

  const latestValue = data[data.length - 1]?.value;

  console.log(`DashboardItem - ${title} - latest value:`, latestValue);
  console.log(`DashboardItem - ${title} - data:`, data);

  return (
    <Card className="dashboard-item">
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5" component="div" align="center">
            {iconMap[title]} {title}
          </Typography>
          <Typography variant="h4" align="center">{latestValue}</Typography>
          <IconButton color="primary" onClick={handleClick}>
            <ShowChartIcon /> View Graph
          </IconButton>
          {showGraph && (
            <ChartModal
              title={title}
              data={data}
              onClose={() => setShowGraph(false)}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardItem;
