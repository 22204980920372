// src/components/ChartModal.js
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartModal = ({ title, data, onClose }) => {
  // Extraire les valeurs et les convertir en nombres
  const values = data.map(entry => {
    const value = parseFloat(entry.value);
    if (isNaN(value)) {
      console.error(`Invalid value for ${title}:`, entry.value);
    }
    return value;
  }).filter(value => !isNaN(value)); // Filtrer les valeurs non numériques
  const labels = data.map(entry => entry.time); // Assurez-vous que la colonne "Time" est correcte

  console.log("ChartModal - values:", values);
  console.log("ChartModal - labels:", labels);

  // Configuration des données du graphique
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: values,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1, // Ajoute une courbe lisse aux lignes
      },
    ],
  };

  // Configuration des options du graphique
  const options = {
    scales: {
      y: {
        beginAtZero: false,
        min: Math.min(...values) - 1, // Ajuste pour éviter que la ligne touche le bord du graphique
        max: Math.max(...values) + 1, // Ajuste pour éviter que la ligne touche le bord du graphique
      },
    },
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{title} Graph</h2>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ChartModal;
