// src/App.js
import React, { useContext } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, Box, Typography } from '@mui/material';
import GoogleSheetProvider, { GoogleSheetContext } from './GoogleSheetProvider';
import Dashboard from './components/Dashboard';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';

const theme = createTheme();

const getStatusIcon = (status) => {
  switch (status) {
    case 'charging':
      return <BatteryChargingFullIcon style={{ color: 'green' }} />;
    case 'discharging':
      return <BatteryAlertIcon style={{ color: 'red' }} />;
    case 'no connected':
    default:
      return <BatteryUnknownIcon style={{ color: 'gray' }} />;
  }
};

const getStatusStyle = (status) => {
  switch (status) {
    case 'charging':
      return { color: 'green' };
    case 'discharging':
      return { color: 'red' };
    case 'no connected':
    default:
      return { color: 'gray' };
  }
};

const Header = () => {
  const data = useContext(GoogleSheetContext);

  if (!data || data.length === 0) return null;

  const status = data[0].Mode.toLowerCase();

  return (
    <Box textAlign="center" mb={4}>
      <Typography variant="h3" component="div">
        Monitoring Dashboard
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center">
        {getStatusIcon(status)}
        <Typography variant="h6" component="span" style={getStatusStyle(status)} ml={1}>
          {status}
        </Typography>
      </Box>
    </Box>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleSheetProvider>
        <Container>
          <Header />
          <Dashboard />
        </Container>
      </GoogleSheetProvider>
    </ThemeProvider>
  );
}

export default App;
