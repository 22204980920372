
// src/GoogleSheetProvider.js
import React, { useEffect, useState } from 'react';

const API_KEY = 'AIzaSyAXORGf8ZDsXTMQtFlDsJUdknyrlCQMJIU';
const SPREADSHEET_ID = '1EyVjkd_6egdcmi7O5sYyjwGIVdgfDZoVc6hR8ThMuU4';
const RANGE = 'Sheet1!A:M'; // Cela lit toutes les données de la feuille nommée 'Sheet1'.



const GoogleSheetProvider = ({ children }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/${RANGE}?key=${API_KEY}`
      );
      const result = await response.json();
      const rows = result.values;
      const headers = rows[0];
      console.log('GoogleSheetProvider - headers:', headers);
      const values = rows.slice(1).map(row => {
        const rowData = headers.reduce((acc, header, i) => {
          acc[header] = row[i];
          return acc;
        }, {});
        console.log('Row data:', rowData);
        return rowData;
      }).filter(row => row.Mode !== "NO Connected");
      setData(values);
      console.log("############################# - fetched data:", values);
    };

    fetchData();
  }, []);

  return (
    <GoogleSheetContext.Provider value={data}>
      {children}
    </GoogleSheetContext.Provider>
  );
};

export const GoogleSheetContext = React.createContext(null);
export default GoogleSheetProvider;
